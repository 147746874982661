import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import { UserSendForgetPasswordEmail } from 'apis/endpoints';

const ForgetPasswordForm = () => {
  // State
  const [email, setEmail] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  // Handler
  const handleSubmit = e => {
    e.preventDefault();

    setIsLoading(true);

    UserSendForgetPasswordEmail(email)
      .then(res => {

        toast.success(`An email is sent to ${email} with password reset link`, {
          theme: 'colored'
        });
        setIsLoading(false);
      })
      .catch(err => {

        setIsLoading(false);
        console.log(err, 'LoginError')
        toast.error(err?.response?.data?.error_messages[0] || 'Send email failed', {
          theme: 'colored'
        });
      });


  };

  return (
    <Form className="mt-4" onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Control
          placeholder={'Email address'}
          value={email}
          name="email"
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Button className="w-100" type="submit" disabled={!email || isLoading}>
          Send reset link {isLoading && '...'}
        </Button>
      </Form.Group>

    </Form>
  );
};

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = { layout: 'simple' };

export default ForgetPasswordForm;
