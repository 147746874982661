import { createSlice } from "@reduxjs/toolkit";



const initialState = {
    uid: -1,
    uname: '',
    name: '',
    lname: '',
    phone_code: '',
    phone_value: '',
    profile: '',
    email: '',
    phone: '',
    isLogged: false,
    loggedate: '',
    agencyID: 0,
    agencyName: '',
}


export const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        setUid: (state, action) => {
            state.uid = action.payload
        },
        setUname: (state, action) => {
            state.uname = action.payload
        },
        setName: (state, action) => {
            state.name = action.payload
        },
        setLName: (state, action) => {
            state.lname = action.payload
        },
        setPhoneCode: (state, action) => {
            state.phone_code = action.payload
        },
        setPhoneValue: (state, action) => {
            state.phone_value = action.payload
        },
        setProfile: (state, action) => {
            state.profile = action.payload
        },
        setEmail: (state, action) => {
            state.email = action.payload
        },
        setPhone: (state, action) => {
            state.phone = action.payload
        },
        setIsLogged: (state, action) => {
            state.isLogged = action.payload
        },
        setloggedate: (state, action) => {
            state.loggedate = action.payload
        },
        setAgencyID: (state, action) => {
            state.agencyID = action.payload
        },
        setAgencyName: (state, action) => {
            state.agencyName = action.payload
        },
    },
});

export const { setUid, setUname, setName, setLName, setPhoneCode, setPhoneValue, setProfile, setEmail, setPhone, setIsLogged, setloggedate, setAgencyID, setAgencyName } = userSlice.actions;

export default userSlice;