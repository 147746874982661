import React, { useEffect } from 'react';
import LogoutContent from 'components/authentication/LogoutContent';
import { useDispatch } from 'react-redux';
import { setAgencyID, setAgencyName, setEmail, setIsLogged, setName, setUid, setUname } from 'store/userSlice';
import { useNavigate } from 'react-router-dom';

const Logout = () => {

  const dispatch = useDispatch()


  useEffect(() => {
    

      dispatch(setUname(''))
      dispatch(setUid(-1))
      dispatch(setEmail(''))
      dispatch(setName(''))
      dispatch(setIsLogged(false));
      dispatch(setAgencyID(0));
      dispatch(setAgencyName(''));
    
  }, [])


  return (


    < div className="text-center" >
      <LogoutContent />
    </div >
  )
};

export default Logout;
