
import FalconCloseButton from 'components/common/FalconCloseButton';
import Typography from 'components/utilities/Typography';
import React, { createRef, useEffect, useState } from 'react';
import { Button, Form, FormControl, InputGroup, Modal } from 'react-bootstrap';
import { BiSolidSearch } from 'react-icons/bi';
import { FcSearch } from 'react-icons/fc';
import { ImSearch } from 'react-icons/im';


function ReactIconSelector({ onSelectIcon, searchValue }) {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const handleIconClick = (Icon) => {
        onSelectIcon(Icon.setName);
        handleClose();

    };


    const [searchIcon, setSearchIcon] = useState('');
    const [MDIIcons, setMDIIcons] = useState([]);

    function mapWithName(Icons, Name) {
        return Object.keys(Icons).map(iconName => ({
            IconComponent: Icons[iconName],
            displayName: iconName,
            displayName: `${Name} ${iconName}`,
            setName: `${Name}/${iconName}`,
        }));
    }

    useEffect(() => {

        // import('react-icons/fa6').then((mdiIcons) => {
        //     setMDIIcons(prevIcons => [
        //         ...prevIcons,
        //         ...mapWithName(mdiIcons, 'fa6')
        //     ]);
        // }).catch()


        import('react-icons/fa6').then((mdiIcons) => {
            setMDIIcons(prevIcons => [...prevIcons, ...mapWithName(mdiIcons, 'fa6')]);
        }).catch();

        import('react-icons/fa').then((mdiIcons) => {
            setMDIIcons(prevIcons => [...prevIcons, ...mapWithName(mdiIcons, 'fa')]);
        }).catch();

        import('react-icons/ai').then((mdiIcons) => {
            setMDIIcons(prevIcons => [...prevIcons, ...mapWithName(mdiIcons, 'ai')]);
        }).catch();

        import('react-icons/bs').then((mdiIcons) => {
            setMDIIcons(prevIcons => [...prevIcons, ...mapWithName(mdiIcons, 'bs')]);
        }).catch();

        import('react-icons/bi').then((mdiIcons) => {
            setMDIIcons(prevIcons => [...prevIcons, ...mapWithName(mdiIcons, 'bi')]);
        }).catch();

        import('react-icons/ci').then((mdiIcons) => {
            setMDIIcons(prevIcons => [...prevIcons, ...mapWithName(mdiIcons, 'ci')]);
        }).catch();

        import('react-icons/cg').then((mdiIcons) => {
            setMDIIcons(prevIcons => [...prevIcons, ...mapWithName(mdiIcons, 'cg')]);
        }).catch();

        import('react-icons/di').then((mdiIcons) => {
            setMDIIcons(prevIcons => [...prevIcons, ...mapWithName(mdiIcons, 'di')]);
        }).catch();

        import('react-icons/fi').then((mdiIcons) => {
            setMDIIcons(prevIcons => [...prevIcons, ...mapWithName(mdiIcons, 'fi')]);
        }).catch();

        import('react-icons/fc').then((mdiIcons) => {
            setMDIIcons(prevIcons => [...prevIcons, ...mapWithName(mdiIcons, 'fc')]);
        }).catch();

        import('react-icons/go').then((mdiIcons) => {
            setMDIIcons(prevIcons => [...prevIcons, ...mapWithName(mdiIcons, 'go')]);
        }).catch();

        import('react-icons/gr').then((mdiIcons) => {
            setMDIIcons(prevIcons => [...prevIcons, ...mapWithName(mdiIcons, 'gr')]);
        }).catch();

        import('react-icons/hi').then((mdiIcons) => {
            setMDIIcons(prevIcons => [...prevIcons, ...mapWithName(mdiIcons, 'hi')]);
        }).catch();

        import('react-icons/hi2').then((mdiIcons) => {
            setMDIIcons(prevIcons => [...prevIcons, ...mapWithName(mdiIcons, 'hi2')]);
        }).catch();

        import('react-icons/im').then((mdiIcons) => {
            setMDIIcons(prevIcons => [...prevIcons, ...mapWithName(mdiIcons, 'im')]);
        }).catch();

        import('react-icons/lia').then((mdiIcons) => {
            setMDIIcons(prevIcons => [...prevIcons, ...mapWithName(mdiIcons, 'lia')]);
        }).catch();

        import('react-icons/io').then((mdiIcons) => {
            setMDIIcons(prevIcons => [...prevIcons, ...mapWithName(mdiIcons, 'io')]);
        }).catch();

        import('react-icons/io5').then((mdiIcons) => {
            setMDIIcons(prevIcons => [...prevIcons, ...mapWithName(mdiIcons, 'io5')]);
        }).catch();

        import('react-icons/lu').then((mdiIcons) => {
            setMDIIcons(prevIcons => [...prevIcons, ...mapWithName(mdiIcons, 'lu')]);
        }).catch();

        import('react-icons/md').then((mdiIcons) => {
            setMDIIcons(prevIcons => [...prevIcons, ...mapWithName(mdiIcons, 'md')]);
        }).catch();

        import('react-icons/pi').then((mdiIcons) => {
            setMDIIcons(prevIcons => [...prevIcons, ...mapWithName(mdiIcons, 'pi')]);
        }).catch();

        import('react-icons/rx').then((mdiIcons) => {
            setMDIIcons(prevIcons => [...prevIcons, ...mapWithName(mdiIcons, 'rx')]);
        }).catch();

        import('react-icons/ri').then((mdiIcons) => {
            setMDIIcons(prevIcons => [...prevIcons, ...mapWithName(mdiIcons, 'ri')]);
        }).catch();

        import('react-icons/si').then((mdiIcons) => {
            setMDIIcons(prevIcons => [...prevIcons, ...mapWithName(mdiIcons, 'si')]);
        }).catch();

        import('react-icons/sl').then((mdiIcons) => {
            setMDIIcons(prevIcons => [...prevIcons, ...mapWithName(mdiIcons, 'sl')]);
        }).catch();

        import('react-icons/tb').then((mdiIcons) => {
            setMDIIcons(prevIcons => [...prevIcons, ...mapWithName(mdiIcons, 'tb')]);
        }).catch();

        import('react-icons/tfi').then((mdiIcons) => {
            setMDIIcons(prevIcons => [...prevIcons, ...mapWithName(mdiIcons, 'tfi')]);
        }).catch();

        import('react-icons/ti').then((mdiIcons) => {
            setMDIIcons(prevIcons => [...prevIcons, ...mapWithName(mdiIcons, 'ti')]);
        }).catch();

        import('react-icons/vsc').then((mdiIcons) => {
            setMDIIcons(prevIcons => [...prevIcons, ...mapWithName(mdiIcons, 'vsc')]);
        }).catch();

        import('react-icons/wi').then((mdiIcons) => {
            setMDIIcons(prevIcons => [...prevIcons, ...mapWithName(mdiIcons, 'wi')]);
        }).catch();

    }, []);




    return (
        <>


            <>
                <Button variant="primary" className='' onClick={handleShow} title='Search Icons'>
                    <ImSearch size={25} color='primary' className='text-white' />
                </Button>

                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size='xl' centered style={{}}>
                    <Modal.Header>
                        <Modal.Title>Search Icons</Modal.Title>
                        <FalconCloseButton onClick={handleClose} />
                    </Modal.Header>
                    <Modal.Body>

                        <div className='w-100 d-flex flex-column'>
                            {/* <div className=''>
                                <ol>
                                    <li>
                                        Search Icon
                                    </li>
                                    <img src='/icon-step-1.png' height={100} className='mb-3' />


                                    <li className=''>
                                        Click on Icon
                                    </li>
                                    <img src='/icon-step-2.png' height={100} className='mb-3' />

                                    <li className=''>
                                        Copy Third Value
                                    </li>
                                    <img src='/icon-step-3.png' height={100} className='mb-2' />

                                    <li className=''>
                                        Paste in Icon Name
                                    </li>
                                </ol>
                            </div> */}
                            {/* <iframe
                                src="https://react-icons.github.io/react-icons/"
                                width="850"
                                height="500"
                                style={{ border: 'none' }}
                                title="React Icons"
                                allowFullScreen
                            ></iframe> */}


                            <div className='px-4'>


                                <Form.Label>Search Icon:</Form.Label>
                                <InputGroup className='mb-2'>
                                    <FormControl value={searchIcon} onChange={e => setSearchIcon(e.target.value)} />
                                </InputGroup>
                            </div>



                            <div className='d-flex flex-row flex-wrap' style={{ padding: 20, overflowY: 'auto', maxHeight: 450, minHeight: 450 }}>
                                {MDIIcons?.filter(datum => datum.displayName.toLowerCase().includes(searchIcon.toLowerCase()))
                                    .slice(0, 200).map((Icon, index) => {

                                        return (
                                            <div key={index} className='m-1 px-2 d-flex flex-column align-items-center '>
                                                <div className='border px-5 py-3 bg-white shadow border-radius-1' style={{ borderRadius: 6, cursor: 'pointer' }} onClick={() => handleIconClick(Icon)}>
                                                    <Icon.IconComponent size='25px' />
                                                </div>
                                                <p className='mt-2' style={{ fontSize: '10px' }}>{Icon.displayName}</p>
                                            </div>
                                        );
                                    })}
                            </div>


                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>

            <script>
                {

                    document.addEventListener('DOMContentLoaded', () => {
                        const item = document.querySelector('.item');
                        if (item) {
                            item.addEventListener('click', () => {
                                // Send a message to the parent window
                                window.parent.postMessage({ type: 'ICON_CLICKED', data: 'SomeData' }, '*');
                            });
                        }
                    })
                }
            </script>


        </>
    );
};

export default ReactIconSelector;
