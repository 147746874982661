import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import classNames from 'classnames';
import { UserChangePassword, UserResetPassword } from 'apis/endpoints';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PasswordChangeForm = ({ hasLabel }) => {

    const uName = useSelector(state => state.MEIUser.email);

    const navigate = useNavigate();
    const location = useLocation();
    const queryString = location.search;

    const queryParams = new URLSearchParams(queryString);
    const uid = queryParams.get('uid');
    const token = queryParams.get('token');


    // State
    const [formData, setFormData] = useState({
        email: uName,
        oldPassword: '',
        password: '',
        confirmPassword: ''
    });


    const [isLoading, setIsLoading] = useState(false);

    // Handler
    const handleSubmit = e => {
        e.preventDefault();


        if (formData.password !== formData.confirmPassword) {
            toast.error('Password and confirm password do not match!', {
                theme: 'colored'
            });
        }


        else {

            UserChangePassword(formData.email, formData.oldPassword, formData.password, formData.confirmPassword)
                .then(res => {

                    toast.success('Password reset successfully!', {
                        theme: 'colored'
                    });
                    setIsLoading(false);
                    navigate('/')
                })
                .catch(err => {

                    setIsLoading(false);
                    console.log(err, 'LoginError')
                    toast.error(err?.response?.data?.error_messages[0] || 'Password reset failed', {
                        theme: 'colored'
                    });
                });
        }



    };

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <Form
            className={classNames('mt-3', { 'text-left': hasLabel })}
            onSubmit={handleSubmit}
        >
            <Form.Group className="mb-3">
                {hasLabel && <Form.Label>Email</Form.Label>}
                <Form.Control
                    placeholder={!hasLabel ? 'Email' : ''}
                    value={formData.email}
                    name="email"
                    onChange={handleFieldChange}
                    type="email"
                />
            </Form.Group>

            <Form.Group className="mb-3">
                {hasLabel && <Form.Label>Old Password</Form.Label>}
                <Form.Control
                    placeholder={!hasLabel ? 'Old Password' : ''}
                    value={formData.oldPassword}
                    name="oldPassword"
                    onChange={handleFieldChange}
                    type="password"
                />
            </Form.Group>

            <Form.Group className="mb-3">
                {hasLabel && <Form.Label>New Password</Form.Label>}
                <Form.Control
                    placeholder={!hasLabel ? 'New Password' : ''}
                    value={formData.password}
                    name="password"
                    onChange={handleFieldChange}
                    type="password"
                />
            </Form.Group>

            <Form.Group className="mb-3">
                {hasLabel && <Form.Label>Confirm Password</Form.Label>}
                <Form.Control
                    placeholder={!hasLabel ? 'Confirm Password' : ''}
                    value={formData.confirmPassword}
                    name="confirmPassword"
                    onChange={handleFieldChange}
                    type="password"
                />
            </Form.Group>

            <Button
                type="submit"
                className="w-100"
                disabled={!formData.email || !formData.oldPassword || !formData.password || !formData.confirmPassword || isLoading}
            >
                Set password {isLoading && '...'}
            </Button>
        </Form>
    );
};

PasswordChangeForm.propTypes = {
    hasLabel: PropTypes.bool
};

export default PasswordChangeForm;
