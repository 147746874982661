import { base_url } from "./constants";
import axios from 'axios';


//  AUTHENTICATION

export const UserLogin = async(email, password, token) =>{
    return await axios.post(`${base_url}/portal/login/`, {
        email: email,
        password: password,
        recaptcha: token
    });
};

export const UserGoogleLogin = async(email, client, appName) =>{
    return await axios.post(`${base_url}/portal/google-signin/`, {
        email: email,
        client_id: client,
        app_name: appName,
    });
};

export const UserSendForgetPasswordEmail = async(email) =>{
    return await axios.post(`${base_url}/portal/send-reset-password/`, {
        email: email
    });
};

export const UserResetPassword = async(uid, token, p1, p2) =>{
    return await axios.post(`${base_url}/portal/reset-password/${uid}/${token}/`, {
        password: p1,
        password2: p2
    });
};

export const UserChangePassword = async(email, op, p1, p2) =>{
    return await axios.post(`${base_url}/portal/change-password/`, {
        email: email,
        password: op,
        new_password: p1,
        new_password2: p2
    });
};


export const AgencyGetProfile = async(agencyID) =>{
    return await axios.get(`${base_url}/portal/agency-profile/${agencyID}/`);
};

export const AgencyPostProfile = async(agencyID, data) =>{
    return await axios.post(`${base_url}/portal/agency-profile/${agencyID}/`, data);
};



export const AgencyGetMarketingCredentials = async(agencyID) =>{
    return await axios.get(`${base_url}/portal/agency-marketing-credentials/${agencyID}/`);
};

export const AgencyPostMarketingCredentials = async(agencyID, data) =>{
    return await axios.post(`${base_url}/portal/agency-marketing-credentials/${agencyID}/`, data);
};




//   TEAMMEMBERS

export const GetTeamMembers = async(agencyID) =>{
    return await axios.get(`${base_url}/portal/team-members/?agency_id=${agencyID}`);
};


export const GetTeamMember = async(id) =>{
    return await axios.get(`${base_url}/portal/team-members/${id}/`);
};



export const PostTeamMember = async(data) =>{
    return await axios.post(`${base_url}/portal/team-members/`, data);
};

export const UpdatePostTeamMember = async(id, data) =>{
    return await axios.post(`${base_url}/portal/team-members/${id}/`, data);
};



export const DeleteTeamMember = async(id) =>{
    return await axios.delete(`${base_url}/portal/team-members/${id}/`);
};




//   CLIENTS

export const GetClients = async(agencyID) =>{
    return await axios.get(`${base_url}/portal/clients/?agency_id=${agencyID}`);
};


export const GetClient = async(id) =>{
    return await axios.get(`${base_url}/portal/clients/${id}/`);
};



export const PostClient = async(data) =>{
    return await axios.post(`${base_url}/portal/clients/`, data);
};

export const UpdatePostClient = async(id, data) =>{
    return await axios.post(`${base_url}/portal/clients/${id}/`, data);
};



export const DeleteClient = async(id) =>{
    return await axios.delete(`${base_url}/portal/clients/${id}/`);
};



//   BANNERS

export const GetBanners = async(agencyID) =>{
    return await axios.get(`${base_url}/portal/banners/?agency_id=${agencyID}`);
};


export const GetBanner = async(id) =>{
    return await axios.get(`${base_url}/portal/banners/${id}/`);
};



export const PostBanner = async(data) =>{
    return await axios.post(`${base_url}/portal/banners/`, data);
};

export const UpdatePostBanner = async(id, data) =>{
    return await axios.post(`${base_url}/portal/banners/${id}/`, data);
};



export const DeleteBanner = async(id) =>{
    return await axios.delete(`${base_url}/portal/banners/${id}/`);
};


export const PostBannerMedia = async(data) =>{
    return await axios.post(`${base_url}/portal/add-banner-media/`, data);
};



//   PAGES

export const GetPages = async(agencyID) =>{
    return await axios.get(`${base_url}/portal/pages/?agency_id=${agencyID}`);
};


export const GetPage = async(id) =>{
    return await axios.get(`${base_url}/portal/pages/${id}/`);
};



export const UpdatePageBanner = async(id, data) =>{
    return await axios.post(`${base_url}/portal/pages/${id}/`, data);
};




//   Service Categories

export const GetSCategories = async(agencyID) =>{
    return await axios.get(`${base_url}/portal/service-categories/?agency_id=${agencyID}`);
};


export const GetSCategory = async(id) =>{
    return await axios.get(`${base_url}/portal/service-categories/${id}/`);
};



export const PostSCategory = async(data) =>{
    return await axios.post(`${base_url}/portal/service-categories/`, data);
};

export const UpdatePostSCategory = async(id, data) =>{
    return await axios.post(`${base_url}/portal/service-categories/${id}/`, data);
};



export const DeleteSCateg = async(id) =>{
    return await axios.delete(`${base_url}/portal/service-categories/${id}/`);
};



//   Services

export const GetServices = async(agencyID) =>{
    return await axios.get(`${base_url}/portal/services/?agency_id=${agencyID}`);
};


export const GetService = async(id) =>{
    return await axios.get(`${base_url}/portal/services/${id}/`);
};



export const PostService = async(data) =>{
    return await axios.post(`${base_url}/portal/services/`, data);
};

export const UpdatePostService = async(id, data) =>{
    return await axios.post(`${base_url}/portal/services/${id}/`, data);
};



export const DeleteService = async(id) =>{
    return await axios.delete(`${base_url}/portal/services/${id}/`);
};






//   Project Categories

export const GetPCategories = async(agencyID) =>{
    return await axios.get(`${base_url}/portal/project-categories/?agency_id=${agencyID}`);
};


export const GetPCategory = async(id) =>{
    return await axios.get(`${base_url}/portal/project-categories/${id}/`);
};



export const PostPCategory = async(data) =>{
    return await axios.post(`${base_url}/portal/project-categories/`, data);
};

export const UpdatePostPCategory = async(id, data) =>{
    return await axios.post(`${base_url}/portal/project-categories/${id}/`, data);
};



export const DeletePCateg = async(id) =>{
    return await axios.delete(`${base_url}/portal/project-categories/${id}/`);
};


//   Industry
export const GetIndustrys = async() =>{
    return await axios.get(`${base_url}/portal/industeries/`);
};


//   Projects
export const GetProjects = async(agencyID) =>{
    return await axios.get(`${base_url}/portal/projects/?agency_id=${agencyID}`);
};


export const GetProject = async(id) =>{
    return await axios.get(`${base_url}/portal/projects/${id}/`);
};



export const PostProject = async(data) =>{
    return await axios.post(`${base_url}/portal/projects/`, data);
};

export const UpdatePostProject = async(id, data) =>{
    return await axios.post(`${base_url}/portal/projects/${id}/`, data);
};



export const DeleteProject = async(id) =>{
    return await axios.delete(`${base_url}/portal/projects/${id}/`);
};




