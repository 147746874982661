import React from 'react';
import PasswordChangeForm from '../ChangePasswordForm';

const ChangePassword = () => (
  <div className="text-center">
    <h5>Change password</h5>
    <PasswordChangeForm  />
  </div>
);

export default ChangePassword;
