import React, { useEffect, useMemo, useRef, useState } from 'react';

// import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


import { base_url, updateImageUrlsMEI } from 'apis/constants';

// Quill.register({ "modules/better-table": QuillBetterTable });

const MEICKEditor = ({value, onchangeValue}) => {




    const quillRef = useRef(null);

    const handleImageUploadQui = async (file) => {
        const ckFdata = new FormData();
        ckFdata.append('upload', file);

        try {
            const response = await fetch(`${base_url}/ckeditor/upload/`, {
                method: 'POST',
                body: ckFdata,
            });

            const result = await response.json();

            if (response.ok) {
                // Assuming the response contains the URL of the uploaded image
                return result.url; // or adjust according to your server response
            } else {
                console.error('Image upload failed:', result);
                return null;
            }
        } catch (error) {
            console.error('Image upload error:', error);
            return null;
        }
    };

    const imageHandler = () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            const file = input.files[0];
            if (file) {
                const url = await handleImageUploadQui(file);
                if (url) {
                    const range = quillRef.current.getEditor().getSelection();
                    quillRef.current.getEditor().insertEmbed(range.index, 'image', url);
                }
            }
        };
    };

    const qqmodules = useMemo(() => ({
        toolbar: {
            container: [
                [{ 'header': [] }, { 'font': [] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                { 'indent': '-1' }, { 'indent': '+1' }],
                [{ 'table': [] }],
                ['link', 'image', 'video'],
                ['formula', ],
                ['clean']
            ],
            handlers: {
                image: imageHandler
            }
        },
        clipboard: {
            matchVisual: false,
        },

    }), []);
    const qformats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video'
    ]

    return (
        <ReactQuill
            ref={quillRef}
            theme='snow'
            value={updateImageUrlsMEI(value)}
            onChange={onchangeValue}
            modules={qqmodules} formats={qformats} bounds={'.app'} />
    );
};

export default MEICKEditor;
