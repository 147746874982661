// DynamicIcon.js
import React from 'react';
import iconMapping from './iconMapping';

const DynamicIcon = ({ iconName, classes, size = '1em' }) => {

    if (!iconName){
        return(<></>)
    }

    const [libraryPrefix, icName] = iconName?.split('/')
    const IconComponent = iconMapping[libraryPrefix]?.[icName] || null;

    return IconComponent ? <IconComponent className={classes} size={size} /> : <div>Icon not found</div>;
};

export default DynamicIcon;
