import React, { useEffect, useState } from 'react';
import { Card, Col, Form, FormControl, InputGroup, Row, Button, Accordion, Modal, Spinner } from 'react-bootstrap';
import { FormProvider } from 'react-hook-form';


import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


import Flex from 'components/common/Flex';

import { useSelector } from 'react-redux';
import { GetTeamMember, PostBanner, PostBannerMedia, PostTeamMember, UpdatePostTeamMember } from 'apis/endpoints';

import { FiDelete } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MEICKEditor from 'components/mei/components/MEICKEditor';
import { base_url } from 'apis/constants';



const UpdateTeamMember = () => {


    
    const agencyID = useSelector(state => state.MEIUser.agencyID);
    const location = useLocation();
    const mID = location.state?.member_id || 0;



    const navigate = useNavigate();

    const [loadingModal, setLoadingModal] = useState(false);


    const [bannerData, setBannerData] = useState({
        name: '',
        company: '',
        designation: '',
        department: '',
        phone: '',
        email: '',
        address: '',
        experience: '',
        skills: '',

        status: true,

        profile: null,
        profilePath: null,
        profileURL: null,

    });






    const loadMemberData = () => {

        GetTeamMember(mID).then(res => {


            setBannerData({
                name: res.data.name,
                company: res.data.company,
                designation: res.data.designation,
                department: res.data.department,
                phone: res.data.phone,
                email: res.data.email,
                address: res.data.address,
                experience: res.data.experience,
                skills: res.data.skills,


                profile: null,
                profilePath: null,
                profileURL: res.data.profile ? `${base_url}${res.data.profile}` : null,

                status: res.data.status,
            });


        }).catch(err => {
            console.log(err)
        });

    };



    useEffect(() => {
        loadMemberData();
    }, [mID]);


    const handleSubmitBanner = (e) => {
        e.preventDefault();

        setLoadingModal(true);


        const finalData = new FormData();

        finalData.append('agency', agencyID)
        finalData.append('name', bannerData.name)
        finalData.append('company', bannerData.company)
        finalData.append('designation', bannerData.designation)
        finalData.append('department', bannerData.department)
        finalData.append('phone', bannerData.phone)
        finalData.append('email', bannerData.email)
        finalData.append('address', bannerData.address)
        finalData.append('experience', bannerData.experience)
        finalData.append('skills', bannerData.skills)
        finalData.append('status', bannerData.status)


        if (!bannerData.profile && !bannerData.profileURL)
            finalData.append('profile', '')

        if (bannerData.profile)
            finalData.append('profile', bannerData.profile)

        UpdatePostTeamMember(mID, finalData).then(res => {

            setLoadingModal(false);
            toast.success(`Team Member updated successfully!`, {
                theme: 'colored'
            });
            navigate('/team-members')

        }).catch(err => {
            const errMsg = Object.values(err?.response?.data)[0][0];
            setLoadingModal(false);
            toast.error(errMsg || 'Team Member update failed!', {
                theme: 'colored'
            });
        });



    };


    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const imageType = /^(?!.*\.webp$)image.*/;

        if (!file?.type?.match(imageType)) {
            toast.error('Only image files are allowed', {
                theme: 'colored'
            });
            return null;
        }
        if (file?.type?.match(imageType)) {
            return { file: file, url: URL.createObjectURL(file), path: file.name }
        }
    };


    return (
        <FormProvider>
            <Modal show={loadingModal} backdrop="static" keyboard={false} centered>

                <Modal.Body>

                    <div className='d-flex justify-content-center'>
                        <Spinner animation="border" variant="info" size='lg' />
                    </div>

                </Modal.Body>

            </Modal>
            <Form onSubmit={handleSubmitBanner}>
                <Row className="g-3">
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                <Row className="flex-between-center">
                                    <Col md>
                                        <h5 className="mb-2 mb-md-0">Edit team member</h5>
                                    </Col>
                                    <Col xs="auto">
                                        <Link to={'/team-members'}>
                                            <Button
                                                variant="link"
                                                className="text-secondary fw-medium p-0 me-3"
                                                type="button"
                                            >
                                                Discard
                                            </Button>
                                        </Link>
                                        <Button variant="primary" type="submit">
                                            Update
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={8}>

                        <Card className="mb-3">
                            <Card.Header as="h6" className="bg-light">
                                Basic information
                            </Card.Header>
                            <Card.Body>
                                <Row className="gx-2 gy-3">
                                    <Col md="12">
                                        <Form.Group>
                                            <Form.Label>Name:</Form.Label>
                                            <InputGroup className="mb-1">
                                                <FormControl value={bannerData.name} onChange={e => setBannerData({ ...bannerData, name: e.target.value })} required />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>

                                    <Col md="6">
                                        <Form.Group>
                                            <Form.Label>Phone:</Form.Label>
                                            <FormControl value={bannerData.phone} onChange={e => setBannerData({ ...bannerData, phone: e.target.value })} required />
                                        </Form.Group>
                                    </Col>
                                    <Col md="6">
                                        <Form.Group>
                                            <Form.Label>Email:</Form.Label>
                                            <FormControl value={bannerData.email} onChange={e => setBannerData({ ...bannerData, email: e.target.value })} required type='email' />
                                        </Form.Group>
                                    </Col>

                                    <Col md="6">
                                        <Form.Group>
                                            <Form.Label>Designation:</Form.Label>
                                            <InputGroup className="mb-1">
                                                <FormControl value={bannerData.designation} onChange={e => setBannerData({ ...bannerData, designation: e.target.value })} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>

                                    <Col md="6">
                                        <Form.Group>
                                            <Form.Label>Department:</Form.Label>
                                            <InputGroup className="mb-1">
                                                <FormControl value={bannerData.department} onChange={e => setBannerData({ ...bannerData, department: e.target.value })} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>

                                    <Col md="12">
                                        <Form.Group>
                                            <Form.Label>Company:</Form.Label>
                                            <InputGroup className="mb-1">
                                                <FormControl value={bannerData.company} onChange={e => setBannerData({ ...bannerData, company: e.target.value })} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>

                                </Row>
                            </Card.Body>
                        </Card>


                        <Card className="mb-3">
                            <Card.Header as="h6" className="bg-light">
                                Address, Experience & Skills :
                            </Card.Header>
                            <Card.Body>
                                <Row className="gx-2 gy-3">
                                    <Col xs="12">
                                        <Form.Label>Address:</Form.Label>
                                        <FormControl as={'textarea'} rows={3} value={bannerData.address} onChange={e => setBannerData({ ...bannerData, address: e.target.value })}  />
                                    </Col>
                                    <Col xs="12">
                                        <Form.Label>Experience:</Form.Label>
                                        <FormControl as={'textarea'} rows={6} value={bannerData.experience} onChange={e => setBannerData({ ...bannerData, experience: e.target.value })}  />
                                    </Col>
                                    <Col xs="12">
                                        <Form.Label>Skills:</Form.Label>
                                        <FormControl as={'textarea'} rows={6} value={bannerData.skills} onChange={e => setBannerData({ ...bannerData, skills: e.target.value })}  />
                                    </Col>

                                </Row>
                            </Card.Body>
                        </Card>

                    </Col>
                    <Col lg={4}>
                        <div className="sticky-sidebar">

                            <Card className="mb-3">
                                <Card.Header as="h6" className="bg-light">
                                    Profile & Status
                                </Card.Header>
                                <Card.Body>
                                    <Row className="gx-2 gy-3">



                                        <Col md="12" className='d-flex flex-column align-items-center'>


                                            <input id="img-team-profile" className='d-none' type='file' onChange={e => {
                                                const file = handleImageUpload(e);
                                                if (file) {
                                                    setBannerData({ ...bannerData, profile: file.file, profileURL: file.url, profilePath: file.path })
                                                }

                                            }} />
                                            <label htmlFor="img-team-profile" className='border rounded-circle py-1 d-flex flex-column justify-content-center position-relative' style={{ height: 180, width: 180 }} >
                                                <div className='position-absolute' style={{ right: -50, bottom: 0 }}>
                                                    <Button variant="link" className="text-secondary fw-medium p-0 me-3" type="button" onClick={e => {
                                                        setBannerData({ ...bannerData, profile: null, profileURL: null, profilePath: null })
                                                    }}>
                                                        {/* <FiDelete className='text-danger' /> */}
                                                        <span className='small text-danger text-decoration-underline'>remove</span>
                                                    </Button>
                                                </div>
                                                {bannerData.profileURL ?
                                                    <div className="d-flex flex-column align-items-center">

                                                        <img
                                                            className="rounded me-2"
                                                            width={80}
                                                            style={{ maxHeight: 140 }}
                                                            src={bannerData.profileURL}
                                                        />
                                                        {/* <br />
                            {bannerData.profilePath} */}
                                                    </div> :
                                                    <Flex justifyContent='center'>
                                                        <img src={cloudUpload} alt="" width={50} className="me-2" />
                                                    </Flex>
                                                }
                                            </label>
                                            <Form.Label className='mt-3'>Profile Image</Form.Label>
                                        </Col>



                                        <Col md="12">
                                            <Form.Group>
                                                <Form.Label>Status:</Form.Label>
                                                <Form.Select value={bannerData.status} onChange={e => setBannerData({ ...bannerData, status: e.target.value === "true" })}>
                                                    <option value="true">Active</option>
                                                    <option value="false">In-Active</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                </Card.Body>
                            </Card>



                        </div>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Row className="flex-between-center">
                                    <Col md>
                                        <h5 className="mb-2 mb-md-0">You're almost done</h5>
                                    </Col>
                                    <Col xs="auto">
                                        <Link to={'/team-members'}>
                                            <Button
                                                variant="link"
                                                className="text-secondary fw-medium p-0 me-3"
                                                type="button"
                                            >
                                                Discard
                                            </Button>
                                        </Link>
                                        <Button variant="primary" type="submit">
                                            Update
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </FormProvider>
    );
};

export default UpdateTeamMember;
