
import coverSrc from 'assets/img/generic/4.jpg';

import icfacebook from 'assets/img/logos/facebook.png';
import ictwitter from 'assets/img/logos/twitter.png';
import icinstagram from 'assets/img/logos/instagram.png';
import iclinkedin from 'assets/img/logos/linkedin.png';
import icyoutube from 'assets/img/logos/youtube.png';

import Flex from 'components/common/Flex';
import VerifiedBadge from 'components/common/VerifiedBadge';
import { Col, Row } from 'react-bootstrap';
import ProfileBanner from '../ProfileBanner';
import { FiGlobe, FiMail, FiMapPin, FiPhone } from 'react-icons/fi';

const Banner = ({ profile = {} }) => {



  const rightSidedItems = [
    {
      title: 'Facebook',
      image: icfacebook,
      href: profile.facebook
    },
    {
      title: 'Twitter',
      image: ictwitter,
      href: profile.twitter
    },
    {
      title: 'Instagram',
      image: icinstagram,
      href: profile.instagram
    },
    {
      title: 'LinkedIn',
      image: iclinkedin,
      href: profile.linkedin
    },
    {
      title: 'Youtube',
      image: icyoutube,
      href: profile.youtube
    },
  ];




  return (
    <ProfileBanner>
      {profile.name &&
        <ProfileBanner.Header avatar={profile.name} coverSrc={coverSrc} />

      }
      <ProfileBanner.Body>
        <Row>
          <Col lg={8}>
            <h4 className="mb-3">
              {profile.name} <VerifiedBadge />
            </h4>


            <Flex alignItems="center" className="mb-2">
              <FiMail className='text-primary me-2' />
              <a href={`mailto:${profile.email}`} className='text-primary'>{profile.email}</a>
            </Flex>

            <Flex alignItems="center" className="mb-2">
              <FiPhone className='text-primary me-2' />
              <a href={`tel:${profile.email}`} className='text-primary'>{profile.phone}</a>
            </Flex>

            <Flex alignItems="center" className="mb-2">
              <FiGlobe className='text-primary me-2' />
              <a href={`${profile.website}`} className='text-primary' target='_blank'>{profile.website}</a>
            </Flex>

            <Flex alignItems="start" className="mb-2" >
              <FiMapPin className='text-primary me-2' />
              <p className='text small'>{profile.address}</p>
            </Flex>


            <div className="border-dashed border-bottom my-4 d-lg-none" />
          </Col>
          <Col className="ps-2 ps-lg-3">
            {rightSidedItems.filter(it => it.href).map(item => (
              <a href={item.href} key={item.title} target='_blank'>
                <Flex alignItems="center" className="mb-2">
                  <img
                    src={item.image}
                    alt={item.title}
                    width={30}
                    className="me-2"
                  />
                  <div className="flex-1">
                    <h6 className="mb-0">{item.title}</h6>
                  </div>
                </Flex>
              </a>
            ))}
          </Col>
        </Row>
      </ProfileBanner.Body>
    </ProfileBanner>
  );
};

export default Banner;
