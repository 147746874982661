import React, { useEffect, useState } from 'react';
import ProfileBanner from './Banner';
import { Card, Col, Form, FormControl, InputGroup, Row, Button, Modal, Spinner } from 'react-bootstrap';
import { FormProvider } from 'react-hook-form';

import cloudUpload from 'assets/img/icons/cloud-upload.svg';

import { toast } from 'react-toastify';
import Flex from 'components/common/Flex';
import { useSelector } from 'react-redux';
import { AgencyGetMarketingCredentials, AgencyGetProfile, AgencyPostMarketingCredentials, AgencyPostProfile } from 'apis/endpoints';
import { base_url } from 'apis/constants';

const MarketingCredentials = () => {

    const agencyId = useSelector(state => state.MEIUser.agencyID);


    const [loadingModal, setLoadingModal] = useState(false);
    const [profile, setProfile] = useState({});

    const [profileData, setProfileData] = useState({});


    const GetProfile = () => {
        AgencyGetProfile(agencyId).then(res => {
            setProfile(res.data)
        }).catch(err => {
            console.log(err)
        });



        AgencyGetMarketingCredentials(agencyId).then(res => {
            setProfileData({
                metaPixelId: res.data.meta_pixel_id,
                clarityProjectID: res.data.clarity_project_id,
                googleAnalyticMeasureID: res.data.google_analytic_measure_id
            })
        }).catch(err => {
            console.log(err)
        });



    }


    useEffect(() => {
        GetProfile();

    }, [agencyId])





    const handleSubmitProfile = (e) => {
        e.preventDefault();



        setLoadingModal(true);

        const finalData = {
            meta_pixel_id: profileData.metaPixelId,
            clarity_project_id: profileData.clarityProjectID,
            google_analytic_measure_id: profileData.googleAnalyticMeasureID,

        };


        AgencyPostMarketingCredentials(agencyId, finalData).then(res => {
            setLoadingModal(false);
            toast.success(`Marketing credentials updated successfully!`, {
                theme: 'colored'
            });
            GetProfile();

        }).catch(err => {
            const errMsg = Object.values(err?.response?.data)[0][0];
            setLoadingModal(false);
            toast.error(errMsg || 'Profile update failed!', {
                theme: 'colored'
            });
        });



    };



    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const imageType = /^(?!.*\.webp$)image.*/;

        if (!file?.type?.match(imageType)) {
            toast.error('Only image files are allowed', {
                theme: 'colored'
            });
            return null;
        }
        if (file?.type?.match(imageType)) {
            return { file: file, url: URL.createObjectURL(file), path: file.name }
        }
    };





    return (
        <>
            <ProfileBanner profile={profile} />





            <FormProvider>
                <Modal show={loadingModal} backdrop="static" keyboard={false} centered>

                    <Modal.Body>

                        <div className='d-flex justify-content-center'>
                            <Spinner animation="border" variant="info" size='lg' />
                        </div>

                    </Modal.Body>

                </Modal>
                <Form onSubmit={handleSubmitProfile}>
                    <Row className="g-3">
                        <Col xs={12}>
                            <Card>
                                <Card.Body>
                                    <Row className="flex-between-center">
                                        <Col md>
                                            <h5 className="mb-2 mb-md-0">Setup your marketing credentials here</h5>
                                        </Col>
                                        <Col xs="auto">
                                            <Button variant="primary" type="submit">
                                                Update Credentials
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col lg={12}>

                            <Card className="mb-3">
                                <Card.Header as="h6" className="bg-light">
                                    Marketing Credentials
                                </Card.Header>
                                <Card.Body>
                                    <Row className="gx-2 gy-3">
                                        <Col md="12">
                                            <Form.Group>
                                                <Form.Label>Meta Pixel ID:</Form.Label>
                                                <FormControl value={profileData.metaPixelId} onChange={e => setProfileData({ ...profileData, metaPixelId: e.target.value })} />
                                            </Form.Group>
                                        </Col>
                                        <Col md="12">
                                            <Form.Group>
                                                <Form.Label>Clarity Project ID:</Form.Label>
                                                <FormControl value={profileData.clarityProjectID} onChange={e => setProfileData({ ...profileData, clarityProjectID: e.target.value })} />
                                            </Form.Group>
                                        </Col>
                                        <Col md="12">
                                            <Form.Group>
                                                <Form.Label>Google Analytic Measure ID:</Form.Label>
                                                <FormControl value={profileData.googleAnalyticMeasureID} onChange={e => setProfileData({ ...profileData, googleAnalyticMeasureID: e.target.value })} />
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                </Card.Body>
                            </Card>



                        </Col>

                        {/* <Col lg={4}>
              <div className="sticky-sidebar">



              </div>
            </Col> */}

                        {/* <Col>
              <Card>
                <Card.Body>
                  <Row className="flex-between-center">
                    <Col md>
                      <h5 className="mb-2 mb-md-0">You're almost done</h5>
                    </Col>
                    <Col xs="auto">

                      <Button variant="primary" type="submit">
                        Set Credentials
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col> */}

                    </Row>
                </Form>
            </FormProvider>

        </>
    );
};

export default MarketingCredentials;
