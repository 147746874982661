import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./userSlice";

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';



const reducers = combineReducers({
  MEIUser: userSlice.reducer,
});

const persistConfig = {
  timeout: 100, 
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),

});

