import React, { useEffect, useState } from 'react';
import { Button, Card, Dropdown, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import { Link, useNavigate } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CustomersTableHeader from './CustomersTableHeader';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { DeleteBanner, GetBanners, GetPages } from 'apis/endpoints';
import { useSelector } from 'react-redux';
import SoftBadge from 'components/common/SoftBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { FiAlertTriangle, FiDelete, FiEdit, FiEdit2, FiEdit3, FiTrash, FiTrash2 } from 'react-icons/fi';
import { toast } from 'react-toastify';








const PagesContent = () => {
    const [pages, setPages] = useState([]);

    const agencyID = useSelector(state => state.MEIUser.agencyID)



    const getPagesData = () => {

        GetPages(agencyID).then(res => {

            setPages(res.data);

        }).catch(err => {

        })

    }


    useEffect(() => {

        getPagesData();


    }, [agencyID]);




    const columns = [
        {
            accessor: 'action-ed',
            Header: '',
            disableSortBy: true,
            cellProps: {
                className: 'text-start',
                style: { width: 120 }
            },

            Cell: (rowData) => {
                const navigate = useNavigate();


                function handleEditBanner() {
                    navigate('/pages-content/update-page', { state: { page_id: rowData.row.original.id } })
                }
                return (
                    <>
                        <OverlayTrigger placement='right' overlay={
                            <Tooltip>
                                View/Edit
                            </Tooltip>
                        }>
                            <Button variant='falcon-primary' className='me-1' size='sm' onClick={e => handleEditBanner()}>
                                <FiEdit />
                            </Button>
                        </OverlayTrigger>
                    </>
                );
            }
        },
        {
            accessor: 'title',
            Header: 'Title',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { title, } = rowData.row.original;
                return (
                    // <Link to="/e-commerce/customer-details">
                        <Flex alignItems="center">
                            <div className="flex-1">
                                <h5 className="mb-0 fs--1">{title}</h5>
                            </div>
                        </Flex>
                    // </Link>
                );
            }
        },
        {
            accessor: 'subtitle',
            Header: 'Sub Title',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { subtitle, } = rowData.row.original;
                return (
                    <Link to="/e-commerce/customer-details">
                        <Flex alignItems="center">
                            <div className="flex-1">
                                <h5 className="mb-0 fs--1">{subtitle}</h5>
                            </div>
                        </Flex>
                    </Link>
                );
            }
        },
        {
            accessor: 'menu',
            Header: 'Menu',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { menu_name, } = rowData.row.original;
                return (
                        <Flex alignItems="center">
                            <div className="flex-1">
                                <h5 className="mb-0 fs--1">{menu_name}</h5>
                            </div>
                        </Flex>
                );
            }
        },


        {
            accessor: 'status',
            Header: 'Status',
            headerProps: {
                className: 'text-center'
            },
            cellProps: {
                className: 'fs-0',
                style: { width: 130 }
            },
            Cell: rowData => {
                const { status } = rowData.row.original;
                return (
                    <SoftBadge
                        pill
                        bg={classNames({
                            success: status === true,
                            danger: status === false,
                        })}
                        className="d-flex flex-center w-fit-content"
                        style={{ width: 'auto' }}
                    >
                        <FontAwesomeIcon
                            icon={classNames({
                                check: status,
                                times: !status,
                            })}
                            transform="shrink-2"
                            className="me-2"
                        />
                        <p className="mb-0">
                            {status ? 'Active' : 'In-Active'}
                        </p>
                    </SoftBadge>
                );
            }
        },

    ];



    return (
        <>

            <AdvanceTableWrapper
                columns={columns}
                data={pages}
                // selection
                sortable
                pagination
                perPage={10}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <CustomersTableHeader table />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
        </>
    );
};

export default PagesContent;
