import React, { useState } from 'react';
import { Card, Col, Form, FormControl, InputGroup, Row, Button, Accordion, Modal, Spinner } from 'react-bootstrap';
import { FormProvider } from 'react-hook-form';


import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


import Flex from 'components/common/Flex';

import { useSelector } from 'react-redux';
import { PostBanner, PostBannerMedia } from 'apis/endpoints';

import { FiDelete } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import MEICKEditor from 'components/mei/components/MEICKEditor';
import DynamicIcon from 'components/mei/components/DynamicIcon';
import ReactIconSelector from 'components/mei/components/IconSelector';



const AddBanner = () => {



  const agencyID = useSelector(state => state.MEIUser.agencyID);


  const navigate = useNavigate();

  const [loadingModal, setLoadingModal] = useState(false);


  const [bannerData, setBannerData] = useState({
    title: '',
    subtitle: '',
    description: '',
    showTitle: true,
    showSubtitle: true,
    showDescription: true,
    alignTitle: 'center',
    alignSubtitle: 'center',
    alignDescription: 'center',
    primaryLabel: '',
    primaryLink: '',
    secondaryLabel: '',
    secondaryLink: '',
    orderNO: 1,
    status: true,
  });


  const mediaObj = {
    pkid: 0,

    index: 1,

    mediaType: 'image',

    bgAltText: '',
    bgHeight: '',
    bgWidth: '',

    fgAltText: '',
    fgHeight: '',
    fgWidth: '',

    brAltText: '',
    brHeight: '',
    brWidth: '',

    icName: '',
    icTitle: '',
    icLink: '',

    vAutoPlay: true,
    vControls: true,
    vLoop: true,
    vMuted: true,

    videoLink: '',
    vdHeight: '',
    vdWidth: '',


    bgImage: null,
    fgImage: null,
    brImage: null,
    bgVideo: null,
    vtImage: null,

    bgImageURL: null,
    fgImageURL: null,
    brImageURL: null,
    bgVideoURL: null,
    vtImageURL: null,

    bgImagePT: null,
    fgImagePT: null,
    brImagePT: null,
    bgVideoPT: null,
    vtImagePT: null,

  };
  const [bannerMedia, setBannerMedia] = useState([
    mediaObj,
  ]);



  function postBannerMedia(value, bid) {
    const finalMediaData = new FormData();


    finalMediaData.append('pk', value.pkid)

    finalMediaData.append('media_type', value.mediaType)

    finalMediaData.append('bg_alt_text', value.bgAltText)
    finalMediaData.append('bg_height', parseInt(value.bgHeight, 10) || 0)
    finalMediaData.append('bg_width', parseInt(value.bgWidth, 10) || 0)

    finalMediaData.append('fg_alt_text', value.fgAltText)
    finalMediaData.append('fg_height', parseInt(value.fgHeight, 10) || 0)
    finalMediaData.append('fg_width', parseInt(value.fgWidth, 10) || 0)

    finalMediaData.append('br_alt_text', value.brAltText)

    finalMediaData.append('icon_name', value.icName)
    finalMediaData.append('icon_title', value.icTitle)
    finalMediaData.append('icon_link', value.icLink)
    
    finalMediaData.append('br_height', parseInt(value.brHeight, 10) || 0)
    finalMediaData.append('br_width', parseInt(value.brWidth, 10) || 0)

    finalMediaData.append('vd_height', parseInt(value.brHeight, 10) || 0)
    finalMediaData.append('vd_width', parseInt(value.brWidth, 10) || 0)

    finalMediaData.append('video_link', value.videoLink)

    finalMediaData.append('v_autoplay', value.vAutoPlay)
    finalMediaData.append('v_controls', value.vControls)
    finalMediaData.append('v_loop', value.vLoop)
    finalMediaData.append('v_muted', value.vMuted)


    if (value.bgImage)
      finalMediaData.append('bg_image', value.bgImage)

    if (value.fgImage)
      finalMediaData.append('fg_image', value.fgImage)

    if (value.brImage)
      finalMediaData.append('br_image', value.brImage)

    if (value.vtImage)
      finalMediaData.append('vt_image', value.vtImage)

    if (value.bgVideo)
      finalMediaData.append('bg_video', value.bgVideo)


    finalMediaData.append('banner_id', bid)


    PostBannerMedia(finalMediaData);

  }

  const handleSubmitBanner = (e) => {
    e.preventDefault();

    setLoadingModal(true);

    const finalData = {
      media: [],
      title: bannerData.title,
      show_title: bannerData.showTitle,
      title_position: bannerData.alignTitle,
      subtitle: bannerData.subtitle,
      show_subtitle: bannerData.showSubtitle,
      subtitle_position: bannerData.alignSubtitle,
      description: bannerData.description,
      show_description: bannerData.showDescription,
      description_position: bannerData.alignDescription,
      status: bannerData.status,
      position: bannerData.orderNO,
      primary_label: bannerData.primaryLabel,
      primary_link: bannerData.primaryLink,
      secondary_label: bannerData.secondaryLabel,
      secondary_link: bannerData.secondaryLink,
      agency: agencyID
    }

    PostBanner(finalData).then(res => {
      // bannerMedia.forEach((bmedia) => postBannerMedia(bmedia, res.data.id))
      const promises = bannerMedia.map(bmedia => postBannerMedia(bmedia, res.data.id));
      Promise.all(promises).then(() => {
        setLoadingModal(false);
        toast.success(`Banner added successfully!`, {
          theme: 'colored'
        });
        navigate('/banners')
      })
    }).catch(err => {
      const errMsg = Object.values(err?.response?.data)[0][0].includes('unique') ? 'Category with same name already exists!' : Object.values(err?.response?.data)[0][0];
      setLoadingModal(false);
      toast.error(errMsg || 'Banner add failed!', {
        theme: 'colored'
      });
    });



  };




  const handleAddMedia = () => {

    setBannerMedia([
      ...bannerMedia,
      {
        ...mediaObj,
        index: bannerMedia[bannerMedia.length - 1].index + 1
      }
    ])

  }


  const handleVideoUpload = (event) => {
    const file = event.target.files[0];
    const videoType = /^video\/.*/; // Regex to match any video type

    if (!file?.type?.match(videoType)) {
      toast.error('Only video files are allowed', {
        theme: 'colored'
      });
      return null;
    }

    return { file: file, url: URL.createObjectURL(file), path: file.name }
  };



  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const imageType = /^(?!.*\.webp$)image.*/;

    if (!file?.type?.match(imageType)) {
      toast.error('Only image files are allowed', {
        theme: 'colored'
      });
      return null;
    }
    if (file?.type?.match(imageType)) {
      return { file: file, url: URL.createObjectURL(file), path: file.name }
    }
  };


  return (
    <FormProvider>
      <Modal show={loadingModal} backdrop="static" keyboard={false} centered>

        <Modal.Body>

          <div className='d-flex justify-content-center'>
            <Spinner animation="border" variant="info" size='lg' />
          </div>

        </Modal.Body>

      </Modal>
      <Form onSubmit={handleSubmitBanner}>
        <Row className="g-3">
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Row className="flex-between-center">
                  <Col md>
                    <h5 className="mb-2 mb-md-0">Add a banner</h5>
                  </Col>
                  <Col xs="auto">
                    <Link to={'/banners'}>
                      <Button
                        variant="link"
                        className="text-secondary fw-medium p-0 me-3"
                        type="button"
                      >
                        Discard
                      </Button>
                    </Link>
                    <Button variant="primary" type="submit">
                      Add banner
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={8}>

            <Card className="mb-3">
              <Card.Header as="h6" className="bg-light">
                Basic information
              </Card.Header>
              <Card.Body>
                <Row className="gx-2 gy-3">
                  <Col md="12">
                    <Form.Group>
                      <Form.Label>Banner Title:</Form.Label>
                      <InputGroup className="mb-1">
                        <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={bannerData.showTitle} onChange={e => setBannerData({ ...bannerData, showTitle: e.target.checked })} />
                        <FormControl value={bannerData.title} onChange={e => setBannerData({ ...bannerData, title: e.target.value })} required />
                      </InputGroup>

                      <Form.Control.Feedback type="invalid">
                        {/* {errors.productName?.message} */}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    <Form.Group>
                      <Form.Label>Subtitle:</Form.Label>
                      <InputGroup className="mb-1">
                        <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={bannerData.showSubtitle} onChange={e => setBannerData({ ...bannerData, showSubtitle: e.target.checked })} />
                        <FormControl value={bannerData.subtitle} onChange={e => setBannerData({ ...bannerData, subtitle: e.target.value })} />
                      </InputGroup>

                      <Form.Control.Feedback type="invalid">
                        {/* {errors.productName?.message} */}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>


                  <Col md="6">
                    <Form.Group>
                      <Form.Label>Primary Button Label:</Form.Label>
                      <FormControl value={bannerData.primaryLabel} onChange={e => setBannerData({ ...bannerData, primaryLabel: e.target.value })} />
                    </Form.Group>
                  </Col>

                  <Col md="6">
                    <Form.Group>
                      <Form.Label>Primary Button Link:</Form.Label>
                      <FormControl value={bannerData.primaryLink} onChange={e => setBannerData({ ...bannerData, primaryLink: e.target.value })} />
                    </Form.Group>
                  </Col>


                  <Col md="6">
                    <Form.Group>
                      <Form.Label>Secondary Button Label:</Form.Label>
                      <FormControl value={bannerData.secondaryLabel} onChange={e => setBannerData({ ...bannerData, secondaryLabel: e.target.value })} />
                    </Form.Group>
                  </Col>

                  <Col md="6">
                    <Form.Group>
                      <Form.Label>Secondary Button Link:</Form.Label>
                      <FormControl value={bannerData.secondaryLink} onChange={e => setBannerData({ ...bannerData, secondaryLink: e.target.value })} />
                    </Form.Group>
                  </Col>



                  {/* <div  className="mb-3">
                    <Form.Check type='radio' id={'check-api-radio'}>
                      <Form.Check.Input type='radio' isValid />
                      <Form.Check.Label className='ms-2'>{'Custom api radio'}</Form.Check.Label>
                    </Form.Check>
                  </div> */}




                </Row>
              </Card.Body>
            </Card>


            <Card className="mb-3">
              <Card.Header as="h6" className="bg-light">
                <InputGroup className="align-items-center">
                  <InputGroup.Checkbox checked={bannerData.showDescription} onChange={e => setBannerData({ ...bannerData, showDescription: e.target.checked })} />
                  <span className='ms-2'>Description</span>
                </InputGroup>
              </Card.Header>
              <Card.Body>
                <Row className="gx-2 gy-3">
                  <Col xs="12">
                    <MEICKEditor value={bannerData.description} onchangeValue={(vl) => {
                      setBannerData(prevState => ({
                        ...prevState,
                        description: vl,
                      }));
                    }} />
                  </Col>

                </Row>
              </Card.Body>
            </Card>



            <Card className="mb-3">
              <Card.Header as="h6" className="bg-light">
                Banner Media
              </Card.Header>
              <Card.Body>
                <Accordion defaultActiveKey={0}>
                  {bannerMedia.map((bann, index) => (
                    <Accordion.Item key={index} eventKey={index}>

                      <Accordion.Header>
                        <div className='text-primary small mb-2 mt-2 d-flex justify-content-between w-100'>
                          <>
                            Banner Media {index + 1}
                          </>
                          {bann.index > 1 &&
                            <Button
                              variant="link"
                              className="text-danger p-0 me-3 small"
                              type="button"
                              onClick={e => {

                                setBannerMedia(bannerMedia.filter(baan => baan.index !== bann.index))

                              }}
                            >
                              <span className='small'>Remove</span>
                            </Button>
                          }
                        </div>
                      </Accordion.Header>

                      <Accordion.Body>


                        <div>

                          <Col md="6">
                            <Form.Group>
                              <Form.Label>Background Type:</Form.Label>
                              <Form.Select value={bann.mediaType} onChange={e => {
                                const updatedBannerMedia = bannerMedia.map((item, i) =>
                                  bann.index === item.index ? { ...item, mediaType: e.target.value } : item
                                );
                                setBannerMedia(updatedBannerMedia)
                              }}>
                                <option value="image">Image</option>
                                <option value="video">Video</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>




                          {bann.mediaType === 'image' ?


                            <>

                              <Form.Label className='mt-3'>Background Image:</Form.Label>
                              <Row className='mb-3'>
                                <Col md={6}>
                                  <Form.Label>Alt Text:</Form.Label>
                                  <FormControl value={bann.bgAltText} onChange={e => {
                                    const updatedBannerMedia = bannerMedia.map((item, i) =>
                                      bann.index === item.index ? { ...item, bgAltText: e.target.value } : item
                                    );
                                    setBannerMedia(updatedBannerMedia)
                                  }} />
                                </Col>
                                <Col md={3}>
                                  <Form.Label>Height:</Form.Label>
                                  <FormControl type="number" value={bann.bgHeight} onChange={e => {
                                    const updatedBannerMedia = bannerMedia.map((item, i) =>
                                      bann.index === item.index ? { ...item, bgHeight: e.target.value } : item
                                    );
                                    setBannerMedia(updatedBannerMedia)
                                  }} />
                                </Col>
                                <Col md={3}>
                                  <Form.Label>Width:</Form.Label>
                                  <FormControl type="number" value={bann.bgWidth} onChange={e => {
                                    const updatedBannerMedia = bannerMedia.map((item, i) =>
                                      bann.index === item.index ? { ...item, bgWidth: e.target.value } : item
                                    );
                                    setBannerMedia(updatedBannerMedia)
                                  }} />
                                </Col>
                              </Row>
                              <input id={`bgimg-${bann.index}`} className='d-none' type='file' onChange={e => {
                                const file = handleImageUpload(e);
                                if (file) {
                                  const updatedBannerMedia = bannerMedia.map((item, i) =>
                                    bann.index === item.index ? { ...item, bgImage: file.file, bgImageURL: file.url, bgImagePT: file.path } : item
                                  );
                                  setBannerMedia(updatedBannerMedia)
                                }

                              }} />
                              <label htmlFor={`bgimg-${bann.index}`} className='dropzone-area py-1 d-flex flex-column justify-content-center position-relative' style={{ height: 180 }} >
                                <div className='position-absolute' style={{ right: 10, top: 20 }}>
                                  <Button variant="link" className="text-secondary fw-medium p-0 me-3" type="button" onClick={e => {
                                    const updatedBannerMedia = bannerMedia.map((item, i) =>
                                      bann.index === item.index ? { ...item, bgImage: null, bgImageURL: null, bgImagePT: null } : item
                                    );
                                    setBannerMedia(updatedBannerMedia)
                                  }}>
                                    <FiDelete className='text-danger' />
                                  </Button>
                                </div>
                                <div className="mb-2">
                                  <img
                                    className="rounded me-2"
                                    width={80}
                                    style={{ maxHeight: 80 }}
                                    src={bann.bgImageURL}
                                  />
                                  {bann.bgImagePT}
                                </div>
                                <Flex justifyContent='center mt-4'>
                                  <p className="fs-0 mb-0 text-700 me-1">Select your file here</p>
                                  <img src={cloudUpload} alt="" width={25} className="me-2" />
                                </Flex>
                              </label>
                            </>

                            :

                            <>

                              <Form.Label className='mt-3 mb-0 '>Background Video:</Form.Label>
                              <div className='text-500 mb-2 small'>Paste a video link or upload video</div>
                              <Row className='mb-3'>
                                <Col md={12}>
                                  <Form.Label>Video Link:</Form.Label>
                                  <FormControl value={bann.videoLink} onChange={e => {
                                    const updatedBannerMedia = bannerMedia.map((item, i) =>
                                      bann.index === item.index ? { ...item, videoLink: e.target.value } : item
                                    );
                                    setBannerMedia(updatedBannerMedia)
                                  }} type='url' />
                                </Col>
                              </Row>
                              <Form.Label className='mt-1 mb-0 '>Upload Video:</Form.Label>
                              <input id={`bgvid-${bann.index}`} className='d-none' type='file' onChange={e => {
                                const file = handleVideoUpload(e);
                                if (file) {
                                  const updatedBannerMedia = bannerMedia.map((item, i) =>
                                    bann.index === item.index ? { ...item, bgVideo: file.file, bgImageURL: file.url, bgImagePT: file.path } : item
                                  );
                                  setBannerMedia(updatedBannerMedia)
                                }

                              }} />
                              <label htmlFor={`bgvid-${bann.index}`} className='dropzone-area py-1 d-flex flex-column justify-content-center position-relative' style={{ height: 180 }} >
                                <div className='position-absolute' style={{ right: 10, top: 20 }}>
                                  <Button variant="link" className="text-secondary fw-medium p-0 me-3" type="button" onClick={e => {
                                    const updatedBannerMedia = bannerMedia.map((item, i) =>
                                      bann.index === item.index ? { ...item, bgVideo: null, bgImageURL: null, bgImagePT: null } : item
                                    );
                                    setBannerMedia(updatedBannerMedia)
                                  }}>
                                    <FiDelete className='text-danger' />
                                  </Button>
                                </div>
                                <div className="mb-2">
                                  {bann.bgImageURL &&
                                    <video
                                      className="rounded me-2"
                                      width={80}
                                      style={{ maxHeight: 80 }}
                                      src={bann.bgImageURL}
                                    />
                                  }
                                  {bann.bgImagePT}
                                </div>
                                <Flex justifyContent='center mt-4'>
                                  <p className="fs-0 mb-0 text-700 me-1">Select your file here</p>
                                  <img src={cloudUpload} alt="" width={25} className="me-2" />
                                </Flex>
                              </label>

                              <Form.Label className='mt-1 mb-0 '>Thumbnail Image:</Form.Label>
                              <input id={`vtimg-${bann.index}`} className='d-none' type='file' onChange={e => {
                                const file = handleImageUpload(e);
                                if (file) {
                                  const updatedBannerMedia = bannerMedia.map((item, i) =>
                                    bann.index === item.index ? { ...item, vtImage: file.file, vtImageURL: file.url, vtImagePT: file.path } : item
                                  );
                                  setBannerMedia(updatedBannerMedia)
                                }

                              }} />
                              <label htmlFor={`vtimg-${bann.index}`} className='dropzone-area py-1 d-flex flex-column justify-content-center position-relative' style={{ height: 180 }} >
                                <div className='position-absolute' style={{ right: 10, top: 20 }}>
                                  <Button variant="link" className="text-secondary fw-medium p-0 me-3" type="button" onClick={e => {
                                    const updatedBannerMedia = bannerMedia.map((item, i) =>
                                      bann.index === item.index ? { ...item, vtImage: null, vtImageURL: null, vtImagePT: null } : item
                                    );
                                    setBannerMedia(updatedBannerMedia)
                                  }}>
                                    <FiDelete className='text-danger' />
                                  </Button>
                                </div>
                                <div className="mb-2">
                                  <img
                                    className="rounded me-2"
                                    width={80}
                                    style={{ maxHeight: 80 }}
                                    src={bann.vtImageURL}
                                  />
                                  {bann.vtImagePT}
                                </div>
                                <Flex justifyContent='center mt-4'>
                                  <p className="fs-0 mb-0 text-700 me-1">Select your file here</p>
                                  <img src={cloudUpload} alt="" width={25} className="me-2" />
                                </Flex>
                              </label>


                              <Row className='mt-3'>
                                <Col md={6}>
                                  <Form.Label>Height:</Form.Label>
                                  <FormControl type="number" value={bann.vdHeight} onChange={e => {
                                    const updatedBannerMedia = bannerMedia.map((item, i) =>
                                      bann.index === item.index ? { ...item, vdHeight: e.target.value } : item
                                    );
                                    setBannerMedia(updatedBannerMedia)
                                  }} />

                                  <Form.Label>Width:</Form.Label>
                                  <FormControl type="number" value={bann.vdWidth} onChange={e => {
                                    const updatedBannerMedia = bannerMedia.map((item, i) =>
                                      bann.index === item.index ? { ...item, vdWidth: e.target.value } : item
                                    );
                                    setBannerMedia(updatedBannerMedia)
                                  }} />

                                </Col>
                                <Col md={6}>
                                  <Form.Check
                                    type='checkbox'
                                    checked={bann.vAutoPlay} onChange={e => {
                                      const updatedBannerMedia = bannerMedia.map((item, i) =>
                                        bann.index === item.index ? { ...item, vAutoPlay: e.target.checked } : item
                                      );
                                      setBannerMedia(updatedBannerMedia)
                                    }}
                                    label='Auto Play'
                                  />
                                  <Form.Check
                                    type='checkbox'
                                    checked={bann.vControls} onChange={e => {
                                      const updatedBannerMedia = bannerMedia.map((item, i) =>
                                        bann.index === item.index ? { ...item, vControls: e.target.checked } : item
                                      );
                                      setBannerMedia(updatedBannerMedia)
                                    }}
                                    label='Controls'
                                  />
                                  <Form.Check
                                    type='checkbox'
                                    checked={bann.vLoop} onChange={e => {
                                      const updatedBannerMedia = bannerMedia.map((item, i) =>
                                        bann.index === item.index ? { ...item, vLoop: e.target.checked } : item
                                      );
                                      setBannerMedia(updatedBannerMedia)
                                    }}
                                    label='Loop'
                                  />
                                  <Form.Check
                                    type='checkbox'
                                    checked={bann.vMuted} onChange={e => {
                                      const updatedBannerMedia = bannerMedia.map((item, i) =>
                                        bann.index === item.index ? { ...item, vMuted: e.target.checked } : item
                                      );
                                      setBannerMedia(updatedBannerMedia)
                                    }}
                                    label='Muted'
                                  />
                                </Col>
                              </Row>

                            </>

                          }


                          <Form.Label className='mt-3'>Forground Image:</Form.Label>
                          <Row className='mb-3'>
                            <Col md={6}>
                              <Form.Label>Alt Text:</Form.Label>
                              <FormControl value={bann.fgAltText} onChange={e => {
                                const updatedBannerMedia = bannerMedia.map((item, i) =>
                                  bann.index === item.index ? { ...item, fgAltText: e.target.value } : item
                                );
                                setBannerMedia(updatedBannerMedia)
                              }} />
                            </Col>
                            <Col md={3}>
                              <Form.Label>Height:</Form.Label>
                              <FormControl type="number" value={bann.fgHeight} onChange={e => {
                                const updatedBannerMedia = bannerMedia.map((item, i) =>
                                  bann.index === item.index ? { ...item, fgHeight: e.target.value } : item
                                );
                                setBannerMedia(updatedBannerMedia)
                              }} />
                            </Col>
                            <Col md={3}>
                              <Form.Label>Width:</Form.Label>
                              <FormControl type="number" value={bann.fgWidth} onChange={e => {
                                const updatedBannerMedia = bannerMedia.map((item, i) =>
                                  bann.index === item.index ? { ...item, fgWidth: e.target.value } : item
                                );
                                setBannerMedia(updatedBannerMedia)
                              }} />
                            </Col>
                          </Row>
                          <input id={`fgimg-${bann.index}`} className='d-none' type='file' onChange={e => {
                            const file = handleImageUpload(e);
                            if (file) {
                              const updatedBannerMedia = bannerMedia.map((item, i) =>
                                bann.index === item.index ? { ...item, fgImage: file.file, fgImageURL: file.url, fgImagePT: file.path } : item
                              );
                              setBannerMedia(updatedBannerMedia)
                            }

                          }} />
                          <label htmlFor={`fgimg-${bann.index}`} className='dropzone-area py-1 d-flex flex-column justify-content-center position-relative' style={{ height: 180 }} >
                            <div className='position-absolute' style={{ right: 10, top: 20 }}>
                              <Button variant="link" className="text-secondary fw-medium p-0 me-3" type="button" onClick={e => {
                                const updatedBannerMedia = bannerMedia.map((item, i) =>
                                  bann.index === item.index ? { ...item, fgImage: null, fgImageURL: null, fgImagePT: null } : item
                                );
                                setBannerMedia(updatedBannerMedia)
                              }}>
                                <FiDelete className='text-danger' />
                              </Button>
                            </div>
                            <div className="mb-2">
                              <img
                                className="rounded me-2"
                                width={80}
                                style={{ maxHeight: 80 }}
                                src={bann.fgImageURL}
                              />
                              {bann.fgImagePT}
                            </div>
                            <Flex justifyContent='center mt-4'>
                              <p className="fs-0 mb-0 text-700 me-1">Select your file here</p>
                              <img src={cloudUpload} alt="" width={25} className="me-2" />
                            </Flex>
                          </label>


                          <Form.Label className='mt-3'>Brand Image:</Form.Label>
                          <Row className='mb-3'>
                            <Col md={6}>
                              <Form.Label>Alt Text:</Form.Label>
                              <FormControl value={bann.brAltText} onChange={e => {
                                const updatedBannerMedia = bannerMedia.map((item, i) =>
                                  bann.index === item.index ? { ...item, brAltText: e.target.value } : item
                                );
                                setBannerMedia(updatedBannerMedia)
                              }} />
                            </Col>
                            <Col md={3}>
                              <Form.Label>Height:</Form.Label>
                              <FormControl type="number" value={bann.brHeight} onChange={e => {
                                const updatedBannerMedia = bannerMedia.map((item, i) =>
                                  bann.index === item.index ? { ...item, brHeight: e.target.value } : item
                                );
                                setBannerMedia(updatedBannerMedia)
                              }} />
                            </Col>
                            <Col md={3}>
                              <Form.Label>Width:</Form.Label>
                              <FormControl type="number" value={bann.brWidth} onChange={e => {
                                const updatedBannerMedia = bannerMedia.map((item, i) =>
                                  bann.index === item.index ? { ...item, brWidth: e.target.value } : item
                                );
                                setBannerMedia(updatedBannerMedia)
                              }} />
                            </Col>
                          </Row>
                          <input id={`brimg-${bann.index}`} className='d-none' type='file' onChange={e => {
                            const file = handleImageUpload(e);
                            if (file) {
                              const updatedBannerMedia = bannerMedia.map((item, i) =>
                                bann.index === item.index ? { ...item, brImage: file.file, brImageURL: file.url, brImagePT: file.path } : item
                              );
                              setBannerMedia(updatedBannerMedia)
                            }

                          }} />
                          <label htmlFor={`brimg-${bann.index}`} className='dropzone-area py-1 d-flex flex-column justify-content-center position-relative' style={{ height: 180 }} >
                            <div className='position-absolute' style={{ right: 10, top: 20 }}>
                              <Button variant="link" className="text-secondary fw-medium p-0 me-3" type="button" onClick={e => {
                                const updatedBannerMedia = bannerMedia.map((item, i) =>
                                  bann.index === item.index ? { ...item, brImage: null, brImageURL: null, brImagePT: null } : item
                                );
                                setBannerMedia(updatedBannerMedia)
                              }}>
                                <FiDelete className='text-danger' />
                              </Button>
                            </div>
                            <div className="mb-2">
                              <img
                                className="rounded me-2"
                                width={80}
                                style={{ maxHeight: 80 }}
                                src={bann.brImageURL}
                              />
                              {bann.brImagePT}
                            </div>
                            <Flex justifyContent='center mt-4'>
                              <p className="fs-0 mb-0 text-700 me-1">Select your file here</p>
                              <img src={cloudUpload} alt="" width={25} className="me-2" />
                            </Flex>
                          </label>




                          <Row className='mb-3 mt-3'>
                            <Col md={12}>
                              <Form.Label>Icon Name:</Form.Label>
                              <InputGroup>
                                <div className='d-flex flex-column justify-content-cente bg-dark-1'>
                                  <InputGroup.Text className='h-100'>
                                    <DynamicIcon iconName={bann.icName} classes={''} />
                                  </InputGroup.Text>
                                </div>
                                <FormControl value={bann.icName} disabled onChange={e => e.preventDefault()} />

                                <Button variant='outline-secondary' className='text-decoration-underline bg-white text-danger' title='Remove Icon' style={{ border: '1px solid var(--falcon-input-border-color)', borderLeft: 0 }} onClick={() => {
                                  const updatedBannerMedia = bannerMedia.map((item, i) =>
                                    bann.index === item.index ? { ...item, icName: '' } : item
                                  );
                                  setBannerMedia(updatedBannerMedia)
                                }}>
                                  <FiDelete className='text-danger' size={25} />
                                  {/* clear */}
                                </Button>

                                <ReactIconSelector onSelectIcon={e => {
                                  const updatedBannerMedia = bannerMedia.map((item, i) =>
                                    bann.index === item.index ? { ...item, icName: e } : item
                                  );
                                  setBannerMedia(updatedBannerMedia)
                                }} />
                              </InputGroup>
                            </Col>
                          </Row>
                          <Row className='mb-3'>
                            <Col md={6}>
                              <Form.Label>Icon Title:</Form.Label>
                              <FormControl value={bann.icTitle} onChange={e => {
                                const updatedBannerMedia = bannerMedia.map((item, i) =>
                                  bann.index === item.index ? { ...item, icTitle: e.target.value } : item
                                );
                                setBannerMedia(updatedBannerMedia)
                              }} />
                            </Col>
                            <Col md={6}>
                              <Form.Label>Icon Link:</Form.Label>
                              <FormControl value={bann.icLink} onChange={e => {
                                const updatedBannerMedia = bannerMedia.map((item, i) =>
                                  bann.index === item.index ? { ...item, icLink: e.target.value } : item
                                );
                                setBannerMedia(updatedBannerMedia)
                              }} />
                            </Col>
                          </Row>




                        </div>


                      </Accordion.Body>

                    </Accordion.Item>

                  ))
                  }
                </Accordion>

                <div className='d-flex justify-content-center mt-2'>
                  <Button onClick={handleAddMedia}>
                    Add another media
                  </Button>
                </div>

              </Card.Body>
            </Card>



          </Col>
          <Col lg={4}>
            <div className="sticky-sidebar">
              {/* <BannerPositions /> */}


              <Card className="mb-3">
                <Card.Header as="h6" className="bg-light">
                  Alignments
                </Card.Header>
                <Card.Body>
                  <Row className="gx-2 gy-3">
                    <Col md="12">
                      <Form.Group>
                        <Form.Label>Title Align:</Form.Label>
                        <Form.Select value={bannerData.alignTitle} onChange={e => setBannerData({ ...bannerData, alignTitle: e.target.value })}>
                          <option value="center">Center</option>
                          <option value="left">Left</option>
                          <option value="right">Right</option>
                          <option value="default">Default</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md="12">
                      <Form.Group>
                        <Form.Label>Subtitle Align:</Form.Label>
                        <Form.Select value={bannerData.alignSubtitle} onChange={e => setBannerData({ ...bannerData, alignSubtitle: e.target.value })}>
                          <option value="center">Center</option>
                          <option value="left">Left</option>
                          <option value="right">Right</option>
                          <option value="default">Default</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md="12">
                      <Form.Group>
                        <Form.Label>Description Align:</Form.Label>
                        <Form.Select value={bannerData.alignDescription} onChange={e => setBannerData({ ...bannerData, alignDescription: e.target.value })}>
                          <option value="center">Center</option>
                          <option value="left">Left</option>
                          <option value="right">Right</option>
                          <option value="default">Default</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>


              <Card className="mb-3">
                <Card.Header as="h6" className="bg-light">
                  Order & Status
                </Card.Header>
                <Card.Body>
                  <Row className="gx-2 gy-3">
                    <Col md="12">
                      <Form.Group>
                        <Form.Label>Order on page:</Form.Label>
                        <FormControl type='number' value={bannerData.orderNO} onChange={e => setBannerData({ ...bannerData, orderNO: e.target.value })} required />
                      </Form.Group>
                    </Col>
                    <Col md="12">
                      <Form.Group>
                        <Form.Label>Status:</Form.Label>
                        <Form.Select value={bannerData.status} onChange={e => setBannerData({ ...bannerData, status: e.target.value === "true" })}>
                          <option value="true">Show on page</option>
                          <option value="false">Don't show on page</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>


            </div>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                <Row className="flex-between-center">
                  <Col md>
                    <h5 className="mb-2 mb-md-0">You're almost done</h5>
                  </Col>
                  <Col xs="auto">
                    <Button
                      variant="link"
                      className="text-secondary fw-medium p-0 me-3"
                      type="button"
                    >
                      Discard
                    </Button>
                    <Button variant="primary" type="submit">
                      Add banner
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default AddBanner;
