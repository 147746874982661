import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import team3 from 'assets/img/team/3.jpg';
import Avatar from 'components/common/Avatar';
import { useSelector } from 'react-redux';

const ProfileDropdown = () => {

  
  const uName = useSelector(state => state.MEIUser.name);

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar name={uName} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          {/* <Dropdown.Item className="fw-bold text-warning" href="#!">
            <FontAwesomeIcon icon="crown" className="me-1" />
            <span>Go Pro</span>
          </Dropdown.Item> */}
          {/* <Dropdown.Divider /> */}
          {/* <Dropdown.Item href="#!">Set status</Dropdown.Item> */}
          <Dropdown.Item as={Link} to="/agency/profile">
            Agency Profile
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/agency/marketing-credentials">
            Marketing Credentials
          </Dropdown.Item>
          {/* <Dropdown.Item href="#!">Feedback</Dropdown.Item> */}
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to="/user/settings">
            Settings
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to="/change-password">
            Change Password
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/logout">
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
